<template>
  <v-container class="mkt-content-container mkt-small-screen mt-5">
    <px-view-header title="Minha empresa" :breadcrumbs="breadcrumbs"/>
    <v-card
      class="mx-auto"
    >
      <v-card-text v-if="loading">
        <px-circular-loading/>
      </v-card-text>
      <v-card-text v-if="!loading">
        <p class="headline mb-1 text--primary">
          {{ entidade.nome }} ({{ entidade.identificador }})
        </p>
      </v-card-text>
    </v-card>
    <px-minha-empresa-tabs class="mt-3" v-if="entidade" :entidade="entidade"/>
  </v-container>
</template>

<script>
import EntidadeService from '@/api/entidade-service';
import MinhaEmpresaTabs from './MinhaEmpresaTabs.vue';

export default {
  data() {
    return {
      loading: false,
      entidade: null,
      breadcrumbs: [
        { text: 'Minha empresa' },
      ],
    };
  },
  methods: {
    getEntidade() {
      this.loading = true;
      EntidadeService.getEntidade()
        .then(({ data }) => {
          this.entidade = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getEntidade();
  },
  components: {
    pxMinhaEmpresaTabs: MinhaEmpresaTabs,
  },
};
</script>
